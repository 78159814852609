import React from "react";
import { graphql, PageProps } from "gatsby";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";

import * as styles from "./Post.module.scss";

import Layout from "@components/Layout";
import Seo from "@components/Seo";
import Comment from "@components/Comment";
import TagList from "@components/Tag/TagList";
import { PostFrontmatterType } from "@components/Post/type";

import MemberData from "./member.yaml";
import PageView from "@components/View/PageView";
import Wrapper from "@components/Wrapper";

type DetailPostProps = PageProps<{ markdownRemark: PostFrontmatterType }, {}>;
interface MemberType {
  id: string;
  emoji?: string;
  comment?: string;
  name?: string;
  image?: ImageDataLike;
  thumb?: string;
}

const Post = ({
  data: {
    markdownRemark: {
      frontmatter: {
        title,
        thumbnail,
        bannerImage,
        category,
        subtitle,
        date,
        tags,
        writer,
      },
      html,
      fields: { slug },
    },
  },
}: DetailPostProps) => {
  const [member] = MemberData.filter((data: MemberType) => data.id === writer);
  const { thumb, emoji, name, comment, job } = member;

  return (
    <Layout>
      <Seo
        title={title}
        description={subtitle}
        url={`${slug}`}
        image={
          getImage(thumbnail?.childImageSharp?.gatsbyImageData)!.images
            ?.fallback?.src
        }
      />
      <div className={styles.thumb}>
        <GatsbyImage
          className={styles.inner}
          image={
            getImage(
              bannerImage
                ? bannerImage?.childImageSharp?.gatsbyImageData
                : thumbnail?.childImageSharp?.gatsbyImageData
            )!
          }
          alt={`올리브영 테크블로그 포스팅 ${title}`}
        />
      </div>
      <Wrapper>
        <PageView>
          <article className={styles.post}>
            <div className={styles.info}>
              <div className={styles.category}>{category}</div>
              <h1 className={styles.tit}>{title}</h1>
              <h2 className={styles.sub}>{subtitle}</h2>
              <h2 className={styles.date}>{date}</h2>
            </div>
            <div
              className="blog-post-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </article>
          {tags && <TagList listType="hightLight" tags={tags} />}
          <article className={styles.member}>
            <div className={styles.profile}>
              <div className={styles.thumnail}>
                <img
                  src={`/member/${thumb}`}
                  alt={`올리브영 테크 블로그 작성 ${title}`}
                />
                <div className={styles.emoji}>
                  <span>{emoji}</span>
                </div>
              </div>
            </div>
            <div className={styles.info}>
              <div className={styles.title}>
                <div className={styles.name}>{name} | </div>
                <div className={styles.job}>{job}</div>
              </div>
              <div className={styles.description}>{comment}</div>
            </div>
          </article>
          <Comment />
        </PageView>
      </Wrapper>
    </Layout>
  );
};

export default Post;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        subtitle
        date(formatString: "YYYY.MM.DD")
        category
        thumbnail {
          childImageSharp {
            gatsbyImageData
          }
        }
        bannerImage {
          childImageSharp {
            gatsbyImageData(quality: 100)
          }
        }
        tags
        writer
      }
      fields {
        slug
      }
    }
  }
`;
