// extracted by mini-css-extract-plugin
export var bookCover = "Post-module--book-cover--9e3f7";
export var bookLink = "Post-module--book-link--b1c86";
export var category = "Post-module--category--b5383";
export var date = "Post-module--date--45041";
export var description = "Post-module--description--23d83";
export var emoji = "Post-module--emoji--b1023";
export var footnote = "Post-module--footnote--aaa26";
export var highlight = "Post-module--highlight--ea042";
export var info = "Post-module--info--cb798";
export var inner = "Post-module--inner--c9fdd";
export var job = "Post-module--job--351f0";
export var lineno = "Post-module--lineno--2521f";
export var member = "Post-module--member--01422";
export var name = "Post-module--name--554d7";
export var post = "Post-module--post--2b6f9";
export var profile = "Post-module--profile--df573";
export var sub = "Post-module--sub--37b92";
export var syntax = "Post-module--syntax--3d3bb";
export var thumb = "Post-module--thumb--bfd4a";
export var thumnail = "Post-module--thumnail--374c0";
export var tit = "Post-module--tit--932c8";
export var title = "Post-module--title--0fdc1";