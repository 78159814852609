import React, { createRef, useLayoutEffect } from "react";

const src = "https://utteranc.es/client.js";

const Comment: React.FC = React.memo(() => {
  const containerRef = createRef<HTMLDivElement>();
  const repo = "oy-techblog/tech-blog-comment";

  useLayoutEffect(() => {
    if (containerRef.current?.firstChild) {
      return;
    }
    const utterances = document.createElement("script");

    const attributes = {
      src,
      repo,
      "issue-term": "pathname",
      label: "comment",
      theme: "github-light",
      crossOrigin: "anonymous",
      async: "true",
    };

    Object.entries(attributes).forEach(([key, value]) => {
      utterances.setAttribute(key, value);
    });
    containerRef.current?.appendChild(utterances);
  }, [repo]);

  return <div ref={containerRef} />;
});

export default Comment;
